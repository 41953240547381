<template>
  <div class="row">
    <div class="col-12">
      <c-step
        stepperGrpCd="IBM_STEP_CD"
        :currentStepCd="impr.ibmStepCd"
        :disabled="true"
        v-model="impr.ibmStepCd">
      </c-step>
        <!-- :items="stepItems"  -->
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
      <div class="row">
        <div class="col-12">
          <q-form ref="editForm1">
            <c-card title="요청&접수 상세" class="cardClassDetailForm">
              <template slot="card-button">
                <q-btn-group outline >
                  <component
                    :is='relationTableInfo.component' 
                    :key='relationTableInfo.key' 
                    :taskParam="relationTableInfo.taskParam"
                    :disabled="!Boolean(popupParam.sopImprovementId)"
                  />
                  <c-btn 
                    v-show="requestBtnEditable" 
                    :isSubmit="isRequest"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="조치부서에 요청" 
                    icon="send"
                    @beforeAction="requestImpr"
                    @btnCallback="requestCallback" />
                  <c-btn 
                    v-show="receiptBtnEditable" 
                    :isSubmit="isReceipt"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="접수" 
                    icon="directions_run"
                    @beforeAction="receiptImpr"
                    @btnCallback="receiptCallback" />
                  <c-btn 
                    v-show="saveBtnEditable1" 
                    :isSubmit="isSaveRequestReceipt"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="LBLSAVE" 
                    icon="save" 
                    @beforeAction="saveRequestReceiptImpr"
                    @btnCallback="saveRequestReceiptCallback" />
                  <c-btn 
                    v-show="requestCancelBtnEditable" 
                    :isSubmit="isReject"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="요청취소" 
                    icon="delete_forever"
                    @beforeAction="rejectImpr"
                    @btnCallback="rejectCallback" />
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                  <c-select
                    :disabled="true"
                    :editable="editable"
                    codeGroupCd="IBM_TASK_TYPE_CD"
                    type="edit"
                    itemText="codeName"
                    itemValue="code"
                    name="ibmTaskTypeCd"
                    label="LBLWORK"
                    v-model="impr.ibmTaskTypeCd"
                  ></c-select>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                  <c-text
                    :disabled="true"
                    :editable="editable"
                    name="sopImprovementId"
                    label="개선번호"
                    v-model="impr.sopImprovementId"
                  ></c-text>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-text
                    :editable="editable"
                    :disabled="true"
                    label="요청부서/이름/요청일"
                    name="request"
                    :value="request">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-text
                    :editable="requestAreaEditable"
                    :required="true"
                    label="LBLTITLE"
                    name="ibmTitle"
                    v-model="impr.ibmTitle">
                  </c-text>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-textarea
                    :editable="requestAreaEditable"
                    :required="true"
                    label="요청내용"
                    name="improveRequestContents"
                    v-model="impr.improveRequestContents">
                  </c-textarea>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div class="row">
                    <div class="col-8">
                      <c-plant
                        :editable="requestAreaEditable"
                        :required="true"
                        type="edit" 
                        name="plantCd" 
                        v-model="impr.plantCd" />
                    </div>
                    <div class="col-4">
                      <c-checkbox
                        :editable="requestAreaEditable"
                        :isFlag="true"
                        trueLabel="업체"
                        falseLabel="LBLDEPT"
                        trueValue="V"
                        falseValue="D"
                        label=""
                        name="actionFlag"
                        v-model="impr.actionFlag"
                        @datachange="changeAction"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-dept 
                    v-show="impr.actionFlag === 'D'"
                    :editable="requestAreaEditable||actionReceiptAreaEditable"
                    :required="impr.actionFlag === 'D'"
                    :searchFlag="actionReceiptAreaEditable ? false : true"
                    :closeFlag="actionReceiptAreaEditable ? false : true"
                    type="edit" 
                    label="조치부서" 
                    name="actionDeptCd" 
                    v-model="impr.actionDeptCd">
                    <template slot="custom-btn-icon">
                      <q-icon v-if="actionReceiptAreaEditable" name="autorenew" @click="changeActionDeptImpr" class="cursor-pointer">
                        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
                          <strong>부서변경</strong>
                        </q-tooltip>
                      </q-icon>
                    </template>
                  </c-dept>
                  <c-vendor 
                    v-show="impr.actionFlag === 'V'"
                    :editable="requestAreaEditable||actionReceiptAreaEditable"
                    :required="impr.actionFlag === 'V'"
                    :selfFlag="false"
                    type="edit" 
                    label="조치업체" 
                    name="actionVendorCd" 
                    v-model="impr.actionVendorCd" />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-multi-field 
                    v-show="impr.actionFlag === 'D'"
                    :editable="actionReceiptAreaEditable"
                    :required="true"
                    :userInfo.sync="userInfo"
                    isArray
                    type="dept_user"
                    label="조치자" 
                    name="actionUserIds" 
                    v-model="impr.actionUserIds" />
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-datepicker
                    :editable="requestAreaEditable"
                    :required="true"
                    label="조치완료 요청일"
                    name="actionCompleteRequestDate"
                    v-model="impr.actionCompleteRequestDate"
                  />
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-field 
                    :editable="requestAreaEditable||actionReceiptAreaEditable"
                    type="dept_user" 
                    :data="impr"
                    deptValue="actionCompleteCheckDeptCd"
                    label="조치완료 확인자" 
                    name="actionCompleteCheckUserId" 
                    v-model="impr.actionCompleteCheckUserId" />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-textarea
                    :editable="actionReceiptAreaEditable"
                    label="요청취소 사유"
                    name="requestRejectReason"
                    v-model="impr.requestRejectReason">
                  </c-textarea>
                </div>
                <div class="col-12">
                  <c-upload 
                    :attachInfo="attachBeforeInfo"
                    :editable="requestAreaEditable||actionReceiptAreaEditable"
                    label="개선 전 사진">
                  </c-upload>
                </div>
              </template>
            </c-card>
          </q-form>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
      <div class="row">
        <div class="col-12" v-show="isTargetReset&&!(impr.completeDateOver > 0 && impr.ibmStepCd !== 'IS00000001')">
          <q-list bordered padding dense class="impr-delay-list">
            <q-item tag="label" v-ripple :active="impr.delayFlag==='Y'" active-class="impr-delay-active">
              <q-item-section side top>
                <q-checkbox 
                  disable
                  true-value="Y"
                  false-value="N"
                  v-model="impr.delayFlag" />
              </q-item-section>

              <q-item-section>
                <q-item-label>목표일 재설정</q-item-label>
                <q-item-label caption>
                  ※ 재설정 시에 지연 프로세스가 동작하며, 재설정한 목표일을 넘길 시 지연 프로세스가 동작하지 않습니다.
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
        <div class="col-12" v-show="overFlag">
          <q-form ref="overForm">
            <c-card title="지연상세" class="cardClassDetailForm">
              <template slot="card-button">
                <q-btn-group outline >
                  <c-btn 
                    v-show="editable&&!disabled" 
                    :isSubmit="isOver"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="LBLSAVE" 
                    icon="save"
                    @beforeAction="saveOverImpr"
                    @btnCallback="saveOverCallback" />
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-datepicker
                    :editable="editable"
                    :disabled="disabled"
                    :required="true"
                    :start="impr.actionCompleteRequestDate"
                    label="목표일"
                    name="targetDate"
                    v-model="impr.targetDate"
                  />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-textarea
                    :editable="editable"
                    :disabled="disabled"
                    :required="true"
                    label="지연사유"
                    name="delayReason"
                    v-model="impr.delayReason">
                  </c-textarea>
                </div>
              </template>
            </c-card>
          </q-form>
        </div>
        <div class="col-12">
          <q-form ref="editForm2">
            <c-card title="조치상세" class="cardClassDetailForm">
              <template slot="card-button">
                <q-btn-group outline >
                  <!-- <c-btn 
                    v-show="actionCompleteBtnEditable" 
                    :isSubmit="isActionComplete"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="조치완료" 
                    icon="directions_run"
                    @beforeAction="actionCompleteImpr"
                    @btnCallback="actionCompleteCallback" /> -->
                  <c-btn 
                    v-show="actionCompleteBtnEditable" 
                    :isSubmit="isComplete"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="개선완료" 
                    icon="check_circle_outline"
                    @beforeAction="completeImpr"
                    @btnCallback="completeCallback" />
                  <c-btn 
                    v-show="saveBtnEditable2" 
                    :isSubmit="isSaveAction"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="LBLSAVE" 
                    icon="save"
                    @beforeAction="saveActionImpr"
                    @btnCallback="saveActionCallback" />
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-datepicker
                    :editable="actionAreaEditable"
                    :required="true"
                    label="조치완료일"
                    name="actionCompleteDate"
                    v-model="impr.actionCompleteDate"
                  />
                </div>
                <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <c-datepicker
                    :editable="actionAreaEditable"
                    :required="true"
                    label="검토완료 요청일"
                    name="reviewCompleteRequestDate"
                    v-model="impr.reviewCompleteRequestDate"
                  />
                </div> -->
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <c-textarea
                    :editable="actionAreaEditable"
                    :required="true"
                    label="조치내용"
                    name="actionContents"
                    v-model="impr.actionContents">
                  </c-textarea>
                </div>
                <div class="col-12">
                  <c-upload 
                    :attachInfo="attachAfterInfo"
                    :editable="actionAreaEditable"
                    label="개선 후 사진">
                  </c-upload>
                </div>
              </template>
            </c-card>
          </q-form>
        </div>
        <!-- <div class="col-12">
          <q-form ref="editForm3">
            <c-card title="검토상세" class="cardClassDetailForm">
              <template slot="card-button">
                <q-btn-group outline >
                  <c-btn 
                    v-show="completeBtnEditable" 
                    :isSubmit="isComplete"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="개선완료" 
                    icon="check_circle_outline"
                    @beforeAction="completeImpr"
                    @btnCallback="completeCallback" />
                  <c-btn 
                    v-show="saveBtnEditable3" 
                    :isSubmit="isSaveCheck"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="LBLSAVE" 
                    icon="save"
                    @beforeAction="saveCheckImpr"
                    @btnCallback="saveCheckCallback" />
                  <c-btn 
                    v-show="rejectBtnEditable" 
                    :isSubmit="isReturn"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="반려" 
                    icon="front_hand"
                    @beforeAction="returnImpr"
                    @btnCallback="returnCallback" />
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-12">
                  <c-textarea
                    :editable="requestCheckAreaEditable"
                    :required="true"
                    label="검토내용"
                    name="reviewContents"
                    v-model="impr.reviewContents">
                  </c-textarea>
                </div>
              </template>
            </c-card>
          </q-form>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'impr-detail',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          isSearch: false,
          sopImprovementId: '',
          ibmTaskTypeCd: '',
          ibmTaskUnderTypeCd: '',  // 업무하위분류코드
          data: null,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    },
    returnData: {
      type: Object,
      default: function() {
        return {
          col1: null,
        }
      },
    }
  },
  data() {
    return {
      impr: {
        sopImprovementId: '',  // 개선 일련번호
        plantCd: '',  // 사업장 코드
        ibmTaskTypeCd: '',  // 업무분류코드
        ibmTaskUnderTypeCd: '',  // 업무하위분류코드
        ibmTitle: '',  // 제목
        request: '',
        improveRequestDeptCd: '',  // 개선요청부서
        improveRequestDeptName: '', // 개선요청부서명
        improveRequestUserId: '',  // 개선요청자
        improveRequestUserName: '',  // 개선요청자명
        improveRequestContents: '',  // 개선요청내용
        ibmStepCd: '',  // 개선진행단계
        relationTableKey: '',  // 관련테이블 키
        relationVendor: '',  // 관련업체_기술
        actionScheduleDate: '',  // 조치예정일
        actionCompleteRequestDate: '',  // 조치완료 요청일
        reviewCompleteRequestDate: '',  // 검토완료 요청일
        actionCompleteDate: '',  // 조치완료일
        actionFlag: 'D',  // 조치부서_조치부서는 변경가능
        actionVendorCd: '',  // 조치부서_조치부서는 변경가능
        actionDeptCd: '',  // 조치부서_조치부서는 변경가능
        actionUserIds: '',  // 조치자s
        actionContents: '',  // 조치내용_조치부서에서
        reviewContents: '',  // 요청부서 조치결과 검토_요청부서에서
        actionCompleteCheckUserId: '',  // 조치완료 확인자_부서장 또는 공장장
        actionCompleteCheckFlag: '',  // 조치완료 확인자 확인 여부
        requestRejectReason: '', // 요청취소사유

        targetDate: '',  // 목표일 - 지연
        delayFlag: 'N', // 지연 강제 프로세스
        delayReason: '',  // 지연사유
        completeDateOver: 0,

        sopConstructionId: '',

        regUserId: '',  // 등록자 ID
        regDt: '',
        chgUserId: '',  // 수정자 ID
        actionUsers: [],
      },
      attachBeforeInfo: {
        isSubmit: '',
        taskClassCd: 'IBM_BEFORE',
        taskKey: '',
      },
      attachAfterInfo: {
        isSubmit: '',
        taskClassCd: 'IBM_AFTER',
        taskKey: '',
      },
      editable: true,
      detailUrl: '',
      saveUrl: '',
      isSaveRequestReceipt: false, // 요청&접수 저장 flag
      isRequest: false, // 요청 flag
      isReceipt: false, // 접수 flag
      isReject: false, // 요청취소 flag
      isActionComplete: false, // 조치완료 flag
      isSaveAction: false, // 조치 저장 flag
      isSaveCheck: false, // 검토 저장 flag
      isComplete: false, // 개선완료 flag
      isReturn: false, // 조치반려 flag
      isOver: false, // 지연 저장 flag
    };
  },
  computed: {
    request() {
      return this.impr.improveRequestDeptName + ' / ' + this.impr.improveRequestUserName + ' / '  + this.impr.regDt
    },
    disabled() {
      return this.popupParam.isSearch || this.impr.ibmStepCd === 'IS00000020';
    },
    imprEditable() {
      return this.editable && !this.disabled && (this.impr.approvalStatusCd === 'ASC9999999' ? true : !this.overFlag)
    },
    requestAreaEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000001' // 요청중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    actionReceiptAreaEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000005' // 조치부서 접수중 상태
        && this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    actionAreaEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000010' // 조치부서 조치중 상태
        && this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    requestCheckAreaEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000015' // 요청부서 검토중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    requestBtnEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000001' // 요청중 상태
        && this.popupParam.sopImprovementId // key가 있는 경우, 저장이 한번 된 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    receiptBtnEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000005' // 조치부서 접수중 상태
        && this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    requestCancelBtnEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000005' // 조치부서 접수중 상태
        && this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    actionCompleteBtnEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000010' // 조치부서 조치중 상태
        && this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    rejectBtnEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000015' // 요청부서 검토중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    completeBtnEditable() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000015' // 요청부서 검토중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    tempSaveBtnEditable() {
      return this.imprEditable 
        && this.popupParam.data && !this.popupParam.sopImprovementId
    },
    saveBtnEditable1() {
      return this.imprEditable 
        && ((this.impr.ibmStepCd === 'IS00000001' // 요청중 and 로그인자의 부서가 요청부서
          && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd)
        || (this.impr.ibmStepCd === 'IS00000005'
         && this.impr.actionDeptCd === this.$store.getters.user.deptCd)) // 조치부서 접수중 and 조치부서인 경우
    },
    saveBtnEditable2() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000010' // 조치부서 조치중 상태
        && this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    saveBtnEditable3() {
      return this.imprEditable 
        && this.impr.ibmStepCd === 'IS00000015' // 요청부서 검토중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    userInfo() {
      return {
        userItems: this.impr.actionUsers,
        userText: 'actionUserName',
        userValue: 'actionUserId',
        deptText: 'actionDeptName',
        deptValue: 'actionDeptCd',
      }
    },
    isTargetReset() {
      /**
       * 목표일 재설정 여부
       */
      return this.editable
        && this.impr.approvalStatusCd !== 'ASC9999999'
        && !this.disabled
         // 조치부서 접수중, 조치중 상태
        && (this.impr.ibmStepCd === 'IS00000005' || this.impr.ibmStepCd === 'IS00000010')
        && (this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
          || this.$_.indexOf(this.impr.actionUserIds, this.$store.getters.user.userId) > - 1 // 조치자인 경우
        )
    },
    overFlag() {
      /**
       * 지연된 데이터 체크
       * 접수중 단계부터 체크하며 지연된 데이터의 경우 지연 프로세스 동작
       * 지연 정보를 제외한 나머지 정보는 비활성화 처리
       */
      return (this.impr.completeDateOver > 0 && this.impr.ibmStepCd !== 'IS00000001')
        || this.impr.delayFlag === 'Y'
    },
    relationTableInfo() {
      return {
        component: () => import(`${'@/pages/sop/ibm/imprRelationTag.vue'}`),
        key: uid(),
        taskParam: this.impr
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.ibm.improve.get.url;
      this.saveUrl = transactionConfig.sop.ibm.improve.update.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.sopImprovementId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopImprovementId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.impr, _result.data);

          this.attachBeforeInfo.taskKey = this.$_.clone(this.impr.sopImprovementId)
          this.attachAfterInfo.taskKey = this.$_.clone(this.impr.sopImprovementId)

          if (this.impr.actionUsers && this.impr.actionUsers.length > 0) {
            this.$set(this.impr, 'actionUserIds', this.$_.map(this.impr.actionUsers, 'actionUserId'))
          } else {
            this.$set(this.impr, 'actionUserIds', [])
          }

          this.returnData.col1 = this.impr;
        },);
      } else {
        /**
         * 데이터가 들어오지 않은 경우 두 가지의 case로 처리
         * 
         * case 1. popupParam.data가 있는 경우
         *  - 해당 데이터에 새로 들어온 데이터가 있음으로 해당 데이터를 impr에 extend 처리
         * case 2. popupParam.data가 없는 경우 경우
         *  - 해당 팝업으로 개선요청을 등록하는 경우로 업무 및 요청정보를 setting한다.
         */
        if (this.popupParam.data) {
          // case 1.
          this.$_.extend(this.impr, this.popupParam.data)
        } else {
          // case 2.
          this.impr.ibmTaskTypeCd = this.popupParam.ibmTaskTypeCd;
          this.impr.ibmTaskUnderTypeCd = this.popupParam.ibmTaskUnderTypeCd;
          this.impr.improveRequestDeptCd = this.$store.getters.user.deptCd;
          this.impr.improveRequestDeptName = this.$store.getters.user.deptName;
          this.impr.improveRequestUserId = this.$store.getters.user.userId;
          this.impr.improveRequestUserName = this.$store.getters.user.userName;
          this.impr.regDt = this.$comm.getToday();
        }
      }
    },
    changeActionDeptImpr() {
      this.isOpen = true;
    },
    changeAction(value) {
      if (value === 'D') {
        // 부서
        this.impr.actionVendorCd = null;
      } else if (value === 'V') {
        // 업체
        this.impr.actionDeptCd = null;
      }
    },
    /**
     * 요청&접수 상세 저장
     */
    saveRequestReceiptImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId
              
              this.isSaveRequestReceipt = !this.isSaveRequestReceipt
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveRequestReceiptCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachBeforeInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 요청
     */
    requestImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '조치부서에 개선요청하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000005';
              
              this.isRequest = !this.isRequest
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    requestCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachBeforeInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 접수
     */
    receiptImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '요청건을 접수하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000010';
              
              this.isReceipt = !this.isReceipt
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    receiptCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.attachBeforeInfo.isSubmit = uid()
      this.getDetail();
    },
    /**
     * 요청취소
     */
    rejectImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          if (this.impr.requestRejectReason) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: '요청취소하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.impr.chgUserId = this.$store.getters.user.userId;
                this.impr.ibmStepCd = 'IS00000001';
                
                this.isReject = !this.isReject
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('ALERT', {
              title: 'LBLGUIDE', // 안내
              message: '요청취소사유를 입력하세요.', // 요청취소사유를 입력하세요.
              type: 'warning', // success / info / warning / error
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    rejectCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachBeforeInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 조치완료
     */
    actionCompleteImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        this.$refs['editForm2'].validate().then(__result => {
          if (_result && __result) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: '조치완료하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.impr.chgUserId = this.$store.getters.user.userId;
                this.impr.ibmStepCd = 'IS00000015';
                
                this.isActionComplete = !this.isActionComplete
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      });
    },
    actionCompleteCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachAfterInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 조치 저장
     */
    saveActionImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        this.$refs['editForm2'].validate().then(__result => {
          if (_result && __result) {
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: 'MSGSAVE', // 저장하시겠습니까?
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.impr.chgUserId = this.$store.getters.user.userId;
                
                this.isSaveAction = !this.isSaveAction
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      });
    },
    saveActionCallback(result) {
      this.popupParam.sopImprovementId = result.data
      this.attachAfterInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 조치 저장
     */
    saveCheckImpr() {
      this.$refs['editForm3'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              
              this.isSaveCheck = !this.isSaveCheck
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCheckCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 개선완료
     */
    completeImpr() {
      this.$refs['editForm2'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '개선 완료처리하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000020';
              
              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 조치반려
     */
    returnImpr() {
      this.$refs['editForm3'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '조치반려하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000010';
              
              this.isReturn = !this.isReturn
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    returnCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    saveOverImpr() {
      this.$refs['overForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              
              this.isOver = !this.isOver
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveOverCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
  }
};
</script>
<style scoped>
.impr-delay-list {
  padding: 0 !important;
}
.impr-delay-active {
  font-weight: 800;
  background: #F2C037
}
</style>